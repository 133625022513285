import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import ContentSubheadingAndTitle from '@components/ContentSubheadingAndTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Flex, Box } from 'theme-ui'
import Hero from '../../../../themes/gatsby-theme-flexiblog-education/src/components/HeroContentStrategy/Hero'
import DividerSmaller from '@components/DividerSmaller'
import IconButton from '@components/IconButton'
import { RiLightbulbLine } from "react-icons/ri"
import HeroBoxed from '@widgets/HeroBoxed'
import Photo from '@widgets/Testimonial/Testimonial.Photo'
import Photo2 from '@widgets/Testimonial/Testimonial.Photo2'
import MemphisPattern from '@components/MemphisPattern'



export default () => (
  <>
    <Seo title='Content Strategy Services' />
    <Stack>
      <Main>
      <Box className='aids'><DividerSmaller  space={4} /></Box>
      <Hero />
      </Main>
    </Stack>

    <Stack>
      <Main>
      <Box className='aids'><DividerSmaller  space={4} /></Box>
        <HeroBoxed.Wrapper>
        <HeroBoxed.RightColumn>
        <Photo2 />
        </HeroBoxed.RightColumn>
          <HeroBoxed.LeftColumn>
          <Box className='after-hero'>
            <PageTitle
          header="Content Strategy Services"
          subheader='Having a compelling content marketing strategy will provide cost-effective benefits for your business in the long run. 
          The importance of organic traffic that drives new leads relies heavily on the strategic layout of your content.'
            />
          </Box>
          </HeroBoxed.LeftColumn>
          <MemphisPattern />
        </HeroBoxed.Wrapper>

        <Divider space={3} />
        <Box sx={{maxWidth:538, minWidth: '45%', display:'inline-block'}}>

          <IconButton name='Content Strategy — Sample 1' Icon={ RiLightbulbLine } variant='vertical' />
          <IconButton name='Content Strategy — Sample 2' Icon={ RiLightbulbLine } variant='vertical' />
          <IconButton name='Content Strategy — Sample 3' Icon={ RiLightbulbLine } variant='vertical' />
          <Box className='CTAbutton'>
          <IconButton name='Click Here to Get Started' variant='vertical' />
          </Box>
          </Box>

          <Box sx={{width:10, display:'inline-block'}}></Box>

          <Box sx={{maxWidth:538, maxHeight:484, minWidth:'50%', display:'inline-block', marginTop:30}}>
          <center><img src='https://i.imgur.com/ZsB1drN.png' className="content-strategy-image"></img></center>
        </Box>

        </Main>
    </Stack>

    <DividerSmaller  space={3} />

    <Stack>
      <Main>
      <HeroBoxed.Wrapper>
      <Box className='services-content'>
      <HeroBoxed.LeftColumn>

<Box className='aids'><DividerSmaller  space={4} /></Box>
<center><img src='https://i.imgur.com/eEhZUaH.jpg' className="content-strategy-image"></img></center>

      <ContentSubheadingAndTitle
          header='Why You Need a Winning Content Strategy'
          subheader='In this day and age, businesses thrive in their online presence. 
          Digital marketing has taken over and the widespread use of the Internet has changed the landscape of marketing forever. 
          You need to ride this wave. However, it’s not as easy as it seems. 
          The competition all over the world wide web is vast. 
          It’s so vast that you need to step up your game. 
          A winning content strategy will take you to that next step. 
          Without this, you won’t drive traffic or raise positive awareness of your company’s online presence.'
        />
 

        <ContentSubheadingAndTitle
          header='Why You Should Hire Us'
          subheader='Our team consists of top-notch SEO experts and seasoned copywriters who can efficiently 
          target the improvement of your content’s visibility. 
          We have been tried and tested in creating quality, engaging articles and compelling copy for digital, outreach and social media. '
        />


 
        <ContentSubheadingAndTitle
          header='Hurry Up And Order Our Services Today!'
          subheader='Not having a winning content strategy is like going to war unarmed. 
          Let your content do the talking and let us help you achieve outstanding results. 
          Whatever niche your business covers, our winning content marketing strategy will lead you closer to your digital marketing goals. '
        />

<Box className='aids'><DividerSmaller  space={4} /></Box>

    </HeroBoxed.LeftColumn></Box>
    </HeroBoxed.Wrapper>
    
    <Box className='aids'><DividerSmaller  space={3} /></Box>

    <HeroBoxed.Wrapper>
        <HeroBoxed.LeftColumn>
        <center>Add Huge Button Here -> Click Now to Visit Our Content Shop!</center>
        </HeroBoxed.LeftColumn>
        </HeroBoxed.Wrapper>

    </Main>
    </Stack>

      <Divider />
    <Box className='learn-more-scroll' id='anchor1'><DividerSmaller  space={4} /></Box>
  </>
)
