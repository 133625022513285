import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'
import { AnchorLink } from "gatsby-plugin-anchor-links"

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  running2: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
    marginBottom: `2rem`
  }
}

export default () => (
  <>
    <Section>
    <Heading variant='h1' sx={styles.heading}>
    Let Us Build You a Winning <span>Content Strategy</span>
      </Heading>
      <Heading variant='h3' sx={styles.running2}>
      We help clients develop a strategy that maximizes their website's potential and drives leads.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='/'>
        Get Content Strategy
      </Button>
      <Button variant='white' as={AnchorLink} to='/content-strategy#anchor1'>
        Learn More
      </Button>
    </Box>
  </>
)
